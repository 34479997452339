.button_error {
    background-color: #2c39bf !important;
    border: 2px solid #000000 !important;
    color: #ffffff !important;
    transition: all .5s !important;
    font-family: "Josefin Sans", Arial, sans-serif;
    padding: 3px 20px !important;
}

.button_error:hover {
    background-color: #000000 !important;
    border: 2px solid #2c39bf !important;
}
