.live-group {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.group-select {
  width: 100%;
}

.container {
  display: block;
  text-align: end;
}

.liveshow-layout {
  display: flex;
  flex-direction: column;
}

.liveshow-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.liveshow-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.liveshow-card {
  display: flex;
  width: 25%;
  padding: 10px;
}

.liveshow-card-inner {
  display: flex;
  flex-direction: column;
}

.liveshow-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}