.facebook-login_registration{
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    align-items: center;
    padding: 0 26px;
    margin: 30vh auto;
}

.btn-facebook {
    background-color: #3b5998;
    border: none;
    color: white;
    padding: 9px 16px;
    font-size: 16px;
    cursor: pointer;
    border: #3b5998;
    border-radius: 8px;
    font-weight: 700;
    text-align: center;
    font-family: Whyte,sans-serif!important;
}

.or-text {
    font-size: 16px;
    line-height: 60px;
    color: #666;
    text-align: center;
    font-weight: 500;
}

.form-control-text {
    color: #282828;
    display: block;
    width: 100%;
    padding-left: 12px;
    box-sizing: border-box;
    user-select: auto;
    font-size: 14px;
    border: 3px solid #000;
    border-radius: 5px;
    height: 50px;
}

.form-control-text:focus {
    border: 3px solid #5551ff;
    padding-left: 12px;
}

.btn1 {
    margin-top: 10px;
    background-color: #000;
    border-color: #000;
    color: #fff;
    height: 48px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 8px;
    padding: 0px 20px;
    font-family: Whyte, sans-serif !important;
}

.btn1:hover {
    color: #000;
    background-color: #fff;
}

.all-link {
    margin-top: 16px;
    justify-content: center;
    align-items: center;
}

.link1 {
    font-size: 12px;
    cursor: pointer;
    margin-top: 16px;
    margin-left: 5px;
}

.no-account-text {
    margin-top: 16px;
    color: rgba(0,0,0,.6);
    font-size: 12px;
}

.loadingPanel {
    display: none;
    position: fixed;
    z-index: 10500;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
}


/* Shared */
.loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #FFF;
}
.loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
}
.loginBtn:focus {
    outline: none;
}
.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
}


@media (max-width: 375px) {
}
