.card1 {
    border: none;
    height: calc(100vh - 57vh);
    overflow: auto;
}

.comment {
    position: relative
}

.comment i {
    position: absolute;
    top: 18px;
    left: 16px;
    color: #8fa2b2
}

.comments-box {
    border: 2px solid red;
    border-radius: 4px;
}

.bdge {
    width: 150px;
    height: 22px;
    background-color: red;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff
}

.commentImage {
    display: block;
    width: 80px;
    height: 80px;
}
.commentImage img {
    display: block;
    width: 100%;
    height: 100%;
}
